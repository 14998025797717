import {VariantProps} from 'class-variance-authority'
import classNames from 'classnames'
import {Route} from 'next'
import {ComponentProps} from 'react'

import {Link, Pathnames} from '@/i18n/routing'

import {buttonClasses} from './ButtonClasses'

interface ButtonProps extends VariantProps<typeof buttonClasses> {
    children: React.ReactNode
    className?: string
    tabIndex?: number
    href: Route<'/'>
}

export default function InternalButton<Pathname extends Pathnames>({
    children,
    href,
    tabIndex,
    variant,
    size,
    shape,
    className,
    ...rest
}: ButtonProps & ComponentProps<typeof Link<Pathname>>) {
    const buttonClass = classNames(buttonClasses({variant, size, shape}), className)
    return (
        <Link href={href} {...rest} type="button" tabIndex={tabIndex} className={buttonClass}>
            {children}
        </Link>
    )
}
