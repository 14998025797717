import {VariantProps} from 'class-variance-authority'
import classNames from 'classnames'

import {heroClasses} from './HeroClasses'

interface HeroProps extends VariantProps<typeof heroClasses> {
    children: React.ReactNode
    className?: string
}

export default function Hero({children, variant, className}: HeroProps) {
    const heroClass = classNames(heroClasses({variant}), className)
    return <div className={heroClass}>{children}</div>
}
