import {VariantProps} from 'class-variance-authority'
import classNames from 'classnames'

import {heroTitleClasses} from './HeroClasses'

interface HeroProps extends VariantProps<typeof heroTitleClasses> {
    children: React.ReactNode
    className?: string
}

export default function HeroTitle({children, variant, position, className}: HeroProps) {
    const heroClass = classNames(heroTitleClasses({variant, position}), className)
    return <h1 className={heroClass}>{children}</h1>
}
