import {cva} from 'class-variance-authority'

export const heroClasses = cva(
    'container mx-auto pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
    {
        variants: {
            variant: {
                primary: 'flex flex-col text-center max-w-3xl',
                secondary: 'max-w-3xl pt-[--page-padding-top]',
                integrations: 'max-w-4xl pt-[--page-padding-top]',
            },
        },
    },
)

export const heroTagClasses = cva('text-center max-w-screen-md', {
    variants: {
        variant: {
            page: 'text-slate-900/85 font-medium text-balance leading-none tracking-[-0.0125em]',
        },
    },
})

export const heroTitleClasses = cva('', {
    variants: {
        variant: {
            primary:
                'text-5xl lg:text-7xl font-semibold text-gradient after:block after:mb-2 tracking-[-0.02em] text-balance p-3',
            secondary: 'text-5xl text-slate-950/90 font-bold',
        },
        position: {
            center: 'text-center',
            left: 'text-left',
        },
    },
})

export const heroSubtitleClasses = cva('max-w-screen-md', {
    variants: {
        variant: {
            primary:
                'md:text-2xl text-xl text-slate-900/75 font-base text-balance mb-16 leading-snug',
            secondary: 'text-xl text-slate-900/65 font-medium text-balance',
            tertiary: 'text-slate-800/85',
        },
        position: {
            center: 'text-center',
            left: 'text-left',
        },
    },
})
