'use client'

import {motion} from 'framer-motion'
import {useTranslations} from 'next-intl'
import {v4 as uuidv4} from 'uuid'

import {navLinks} from '../navigation/NavLinks'
import InternalButton from '../ui/internalbutton'
import Hero from './Hero'
import HeroSubtitle from './HeroSubtitle'
import HeroTitle from './HeroTitle'

const fadeInUp = {
    hidden: {opacity: 0, y: 80, filter: 'blur(10px)'},
    visible: {opacity: 1, y: 0, filter: 'blur(0px)'},
}

export default function HeroMain() {
    const t = useTranslations()
    const renderTextWithBlurEffect = (text: string) => {
        const words = text.split(' ')
        return words.map((word, index) => (
            <motion.span
                key={uuidv4()}
                className="inline-block"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{
                    duration: 0.6,
                    delay: 0.02 * index,
                }}
            >
                {word}
                {index < words.length - 1 && <span>&nbsp;</span>}
            </motion.span>
        ))
    }
    return (
        <Hero variant="primary" className="mt-32 items-center">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{duration: 0.6}}
                className="blur-content"
            >
                <HeroTitle variant="primary">{t('Main.hero')}</HeroTitle>
            </motion.div>
            <motion.div initial="hidden" animate="visible" className="blur-content">
                <HeroSubtitle variant="primary">
                    {renderTextWithBlurEffect(t('Main.herosub'))}
                </HeroSubtitle>
            </motion.div>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{duration: 0.5, delay: 0.6}}
                className="blur-content"
            >
                <InternalButton
                    href={navLinks.contact}
                    variant="vibrant"
                    size="xlarge"
                    shape="round"
                >
                    {t('Main.largeCTA')}
                </InternalButton>
            </motion.div>
        </Hero>
    )
}
