import {routing} from '@/i18n/routing'

export const navLinks = {
    product: '/product',
    analytics: '/product/analytics',
    integrations: '/integrations',
    security: '/security',
    pricing: '/pricing',
    changelog: '/changelog',
    customers: '/customers',
    about: '/about',
    blog: '/blog',
    contact: '/contact',
    appointments: '/#appointments',
    medical: '/#medical',
    finance: '/#finance',
} satisfies Record<string, keyof typeof routing.pathnames>
