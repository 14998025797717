'use client'

import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/outline'
import Image from 'next/image'
import {useCallback, useMemo, useRef, useState} from 'react'

import {buttonClasses} from '@/app/[locale]/(website)/components/ui/ButtonClasses'

interface DataCarouselClientProps {
    data: Array<{
        id: string
        imgSrc?: string
        imgAlt?: string
        title?: string
        subtitle?: string
    }>
}

export default function CarouselClient({data}: DataCarouselClientProps) {
    const sliderRef = useRef<HTMLUListElement | null>(null)
    const [sliderPosition, setSliderPosition] = useState(0)
    const slideWidth = 396
    const slideMargin = 14

    const scrollToSlide = useCallback((slideIndex: number) => {
        const slider = sliderRef.current
        if (!slider) return
        const newScrollPosition = slideIndex * (slideWidth + slideMargin)
        slider.scrollTo({
            left: newScrollPosition,
            behavior: 'smooth',
        })
    }, [])

    const currentSlide = useMemo(() => {
        return Math.floor(sliderPosition / (slideWidth + slideMargin))
    }, [sliderPosition])

    const scrolledToEndOfSlider = useMemo(() => {
        const slider = sliderRef.current
        if (!slider) return false
        const {scrollWidth, clientWidth} = slider
        return sliderPosition >= scrollWidth - clientWidth
    }, [sliderPosition])

    const goToNextSlide = useCallback(() => {
        if (currentSlide < data.length) {
            scrollToSlide(currentSlide + 1)
        }
    }, [currentSlide, data.length, scrollToSlide])

    const goToPreviousSlide = useCallback(() => {
        if (currentSlide > 0) {
            scrollToSlide(currentSlide - 1)
        }
    }, [currentSlide, scrollToSlide])

    const handleScroll = useCallback((ev: React.UIEvent<HTMLUListElement>) => {
        setSliderPosition(ev.currentTarget.scrollLeft)
    }, [])

    return (
        <>
            <ul
                ref={sliderRef}
                onScroll={handleScroll}
                className="flex h-[500px] scrollbar-hide overflow-x-auto snap-x snap-mandatory slide-p-r gap-[14px]"
            >
                {data.map((item) => (
                    <li key={item.id} className="snap-start snap-always">
                        <div className="slide-center flex flex-col justify-end relative bg-white border w-[396px] h-full rounded-xl shrink-0 p-6">
                            {item.imgSrc && (
                                <Image
                                    src={item.imgSrc}
                                    alt={item.imgAlt || ''}
                                    className="w-full h-auto"
                                    width={350}
                                />
                            )}
                            {item.subtitle && <span>{item.subtitle}</span>}
                            {item.title && (
                                <h3 className="text-xl text-slate-950/95 font-medium">
                                    {item.title}
                                </h3>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
            {data.length > 3 && (
                <div className="flex justify-center gap-2 mt-12">
                    <button
                        disabled={currentSlide === 0}
                        onClick={goToPreviousSlide}
                        type="button"
                        className={buttonClasses({
                            variant: 'chevronCarousel',
                        })}
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="size-5" />
                    </button>
                    <button
                        disabled={scrolledToEndOfSlider}
                        onClick={goToNextSlide}
                        type="button"
                        className={buttonClasses({
                            variant: 'chevronCarousel',
                        })}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="size-5" />
                    </button>
                </div>
            )}
        </>
    )
}
